
/* bg anim */
.bg {
  animation:slide 4s ease-in-out infinite alternate;
  background-image: linear-gradient(-60deg, #2b4766 50%, #3f5c7d 50%);
  bottom:0;
  left:-50%;
  opacity:.5;
  position:fixed;
  right:-50%;
  top:0;
  z-index:-1;
  height: 48vh;
}

.bg2 {
  animation-direction:alternate-reverse;
  animation-duration:5s;
}

.bg3 {
  animation-duration:6s;
}

@keyframes slide {
  0% {
    transform:translateX(-25%);
  }
  100% {
    transform:translateX(25%);
  }
}

/* Company name animation */
div.companyname{
  /* background-color: #3f5c7d; */
  height: 48vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

div.companyname span{
  font-family: 'Bebas Neue', cursive;
  font-size: 18vh;
}

div.companyname-animation {
  position: absolute;
  top: 32.5vh;
}

div.companyname-animation span {
  position: relative;
  display: inline-block;
  color: #f3f3f3;
  text-transform: uppercase;
  animation: flip 4s infinite;
  animation-delay: calc(.2s * var(--i));
}

@keyframes flip {
  0%,75% {
    transform: rotateY(360deg) 
  }
}
/* EOF Company name animation */

div.info{
  height: 45vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
div.info p b.title{
  font-family: 'Bebas Neue', cursive;
  font-size: 2.5vh;
}
p{
  font-size: 2vh;
  color:#3f5c7d;
}
hr{
  border:0;
  border-top:1px solid #3f5c7d;
  padding-bottom: 2vh;
  margin-top: 1.5vh;
}

.billing p{
  font-size: 1.5vh;
}

a:visited, a:hover, a, link{
  color: #3f5c7d;
}